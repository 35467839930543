import { useState, useEffect } from 'react';
import { useRouter } from 'next/router';

// styles
import styles from './moduleview.scss';

// types
import type { FormPayload } from '../../content-elements-payload';

// components
import {
  BUTTON_COLORS,
  BUTTON_BACKGROUND,
  BUTTON_ALIGNMENT,
  BUTTON_TYPES,
  Button,
} from 'components/Button/Button';
import { Lightbox } from 'components/LightBox/Lightbox';
import { Translation } from 'components/Translation/Translation';
import ModuleView from './ModuleView';
import { InnerHtml } from 'components/InnerHtml/inner-html';
import { InlineEdit } from 'components/ContentCreator/InlineEdit';
import { FormController } from '../../Form/form-controller';

// utils
import { generateSlug } from 'utils/slug';

interface FormModuleProps {
  headline: string;
  icon: string;
  text: string;
  buttonText: string;
  form: FormPayload;
  tileClass: string;
  index: number;
}

export function FormModule({
  headline,
  icon,
  text,
  buttonText,
  form,
  tileClass,
  index,
}: Readonly<FormModuleProps>) {
  const router = useRouter();
  const hash = router.asPath.split('#')?.[1];
  const [modalOpen, setModalOpen] = useState(false);
  const id = generateSlug(headline);

  useEffect(() => {
    // open the regarding modal if hash is given in the url
    if (hash === id) {
      setModalOpen(true);
    }
  }, [hash, id]);

  const openModal = () => {
    setModalOpen(true);
    const hash = `#${id}`;
    // needs to set directly on window, otherwise there is no hash in the url
    window.history.replaceState(null, null, hash);
  };

  const closeModal = () => {
    setModalOpen(false);
    const url = window.location.href.split('#')[0];
    window.history.pushState({ path: url }, null, url);
  };

  return (
    <ModuleView headline={headline} icon={icon} text={text} tileClass={tileClass} index={index}>
      {buttonText && (
        <div className={styles.formButtonWrapper}>
          <Button
            color={BUTTON_COLORS.WHITE}
            background={BUTTON_BACKGROUND.PRIMARY}
            align={BUTTON_ALIGNMENT.CENTER}
            symbol="arrow-link-bold-right"
            onClick={openModal}
            noTracking
          >
            <InlineEdit previewId="#st_buttonText">{buttonText}</InlineEdit>
          </Button>
        </div>
      )}
      {form && modalOpen && (
        <Lightbox onCloseRequest={closeModal}>
          <div className="c-lightbox__container ">
            <div className="c-lightbox__title">
              {headline && <InnerHtml as="h3" content={headline} />}
              <Button
                symbol="close"
                type={BUTTON_TYPES.PLAIN}
                onClick={closeModal}
                className="c-lightbox__close"
                noTracking
              >
                <Translation id="web20_lightbox_close" />
              </Button>
            </div>
            <div className={styles.leadForm}>
              <FormController isModal {...form} formType="leadmodule" />
            </div>
          </div>
        </Lightbox>
      )}
    </ModuleView>
  );
}
