import { useState } from 'react';
import key from 'weak-key';

// components
import { Lightbox } from '../LightBox/Lightbox';
import {
  BUTTON_COLORS,
  BUTTON_ALIGNMENT,
  BUTTON_BACKGROUND,
  BUTTON_TYPES,
  Button,
} from '../Button/Button';
import { Translation } from '../Translation/Translation';
import { Loader } from '../Loader/Loader';
import { InlineEdit } from '../ContentCreator/InlineEdit';

interface ToolEmbedProps {
  src: string;
  label: string;
  lightBoxStyle?: React.CSSProperties;
  hasInedit?: boolean;
  hasArrow?: boolean;
}

export function ToolEmbed({
  src,
  label,
  lightBoxStyle,
  hasInedit = false,
  hasArrow = false,
}: Readonly<ToolEmbedProps>) {
  const [modalOpen, setModalOpen] = useState(false);
  const [iframeLoaded, setIframeLoaded] = useState(false);

  return (
    <div className="c-toolembed">
      <Button
        color={BUTTON_COLORS.WHITE}
        background={BUTTON_BACKGROUND.PRIMARY}
        align={BUTTON_ALIGNMENT.CENTER}
        symbol={hasArrow ? 'arrow-link-bold-right' : ''}
        onClick={() => setModalOpen(true)}
      >
        <InlineEdit previewId={hasInedit ? '#st_buttonText' : undefined}>{label}</InlineEdit>
      </Button>
      {modalOpen && (
        <Lightbox
          additionalClass="c-toolembed-lightbox"
          additionalStyle={lightBoxStyle}
          onCloseRequest={() => setModalOpen(false)}
        >
          <Button
            onClick={() => setModalOpen(false)}
            className="c-lightbox__close"
            symbol="close"
            type={BUTTON_TYPES.PLAIN}
            noTracking
          >
            <Translation id="web20_lightbox_close" />
          </Button>
          <div className="c-lightbox__container">
            <div className="c-toolembed__wrapper">
              {!iframeLoaded && <Loader />}
              <iframe
                src={src}
                title={key({ src })}
                height="100%"
                width="100%"
                onLoad={() => setIframeLoaded(true)}
              />
            </div>
          </div>
        </Lightbox>
      )}
    </div>
  );
}
