import React from 'react';

// components
import ModuleView from './ModuleView';
import { InternalLink } from '../../../Link/InternalLink';
import { ExternalLink } from '../../../Link/ExternalLink';
import { InlineEdit } from '../../../ContentCreator/InlineEdit';

// styles
import styles from './moduleview.scss';
import { useTracking as useTracking2 } from 'utils/tracking/track';

interface LinkModuleProps {
  headline: string | null;
  icon: string | null;
  text: string | null;
  url: string | null;
  buttonText: string | null;
  tileClass: string | null;
  window: string | null;
  popup: string | null;
  index: number;
}
function LinkModule({
  index,
  headline = null,
  icon = null,
  text = null,
  url = null,
  buttonText = null,
  tileClass = null,
  window = null,
  popup = null,
}: Readonly<LinkModuleProps>) {
  const { trackClick } = useTracking2();

  const Link = /^[a-zA-Z]+:\/\//.test(url) ? ExternalLink : InternalLink;

  function clickHandler() {
    trackClick({
      click_intent: 'cta',
      click_element: `lead-module_${headline}`,
      click_text: buttonText ?? undefined,
    });
  }

  return (
    <ModuleView headline={headline} icon={icon} text={text} tileClass={tileClass} index={index}>
      <div className={styles.formButtonWrapper}>
        <Link
          href={url}
          content={{
            target: url,
            window,
            popup,
            showArrow: false,
          }}
          tracking={clickHandler}
          className="button button--white button--background-primary button--center"
        >
          <InlineEdit previewId="#st_buttonText">{buttonText}</InlineEdit>
        </Link>
      </div>
    </ModuleView>
  );
}

export default LinkModule;
