import key from 'weak-key';

// style
import styles from './leadmodule.scss';

// components
import { Title } from '../Title/Title';
import IframeModule from './Module/IframeModule';
import LinkModule from './Module/LinkModule';
import { FormModule } from './Module/FormModule';

// utils
import { useSectionId } from 'utils/hooks/use-section-id';
import { isEmpty } from 'utils/is-empty';
import { TitleFormats } from '../Title/title.types';

export interface LeadModuleProps {
  title: string;
  anchor: string;
  subtitle: string;
  modules: any[];
  contentIndex: number;
  titleInAnchor: boolean;
  isSectionReference: boolean;
}

export function LeadModule({
  title,
  anchor,
  subtitle,
  modules,
  contentIndex,
  titleInAnchor,
  isSectionReference,
}: Readonly<LeadModuleProps>) {
  const sectionId = useSectionId(title, anchor, titleInAnchor, contentIndex);

  if (isEmpty(modules)) return null;

  const tileClass =
    modules.length > 3 || modules.length === 1 ? 'medium-6 large-6' : 'medium-6 large-4';
  const isSingle = modules.length === 1;
  const isCentered = isSingle && !title ? 'align-center' : '';
  const moduleTypes = {
    lead_module_iframe: IframeModule,
    lead_module_link: LinkModule,
    lead_module_form: FormModule,
  };

  return (
    <div className={styles.wrapper} id={sectionId}>
      <div className="grid-container c-leadmodule">
        {!isSingle && (
          <Title
            title={title}
            subtitle={subtitle}
            Format={TitleFormats.h2}
            titlePreviewId="#st_title"
            subtitlePreviewId="#st_subtitle"
            isSectionReference={isSectionReference}
            formatClassName="h3"
          />
        )}
        <div className={`grid-x grid-margin-x ${isCentered}`} data-preview-id="#st_leadModules">
          {isSingle && (
            <div className={`${styles.singleTitle} large-6 medium-6 cell`}>
              <Title
                title={title}
                subtitle={subtitle}
                Format={TitleFormats.h2}
                titlePreviewId="#st_title"
                subtitlePreviewId="#st_subtitle"
                isSectionReference={isSectionReference}
                formatClassName="h3"
              />
            </div>
          )}
          {!isEmpty(modules)
            ? modules.map((module, index) => {
                const Module = moduleTypes[module.type] || FormModule;
                return <Module key={key(module)} {...module} tileClass={tileClass} index={index} />;
              })
            : null}
        </div>
      </div>
    </div>
  );
}
